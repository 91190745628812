<template>
  <div class="diagnosis_box">
    <div class="htop">
      <div class="head">
        <span>{{ selectItem.DiagnosisYear }}年</span>
        <span>{{ selectItem.DiagnosisName }}</span>
        <span>{{ selectItem.SubjectTypeStr }}</span>
      </div>
    </div>
    <div class="sform">
      <div class="clear"></div>
      <div class="box">
        <div class="title">诊断分数：</div>
        <el-input v-model.number="score" placeholder="请输入诊断分数" maxlength="3"></el-input>
        <el-button @click="activateDiagnosis" type="primary">换算</el-button>
        <div class="clear"></div>
      </div>
      <div v-if="showResult" class="show">
        <div class="title">相当于近年高考</div>
        <span class="show-item">成绩：<span class="show-content">{{ result.Score || '-' }}</span></span>
        <span class="show-item">全省排名：<span class="show-content">{{ result.Rank || '-' }}</span></span>
        <div class="clear" style="height:40px;"></div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'
// import { LineDiagnosisScore } from '@/api/common'

export default {
  name: "diagnosis-info",
  data(){
    return{
      score: '',
      result: {},
      showResult: false,
      selectItem: {}
    }
  },
  mounted(){
    this.selectItem = JSON.parse(this.$route.query.info)
  },
  methods: {
    // 计算分数
    async activateDiagnosis(){
      if(!this.score && this.score !== 0){
        this.$message({
          type: 'error',
          message: '请输入诊断成绩！',
          center: true,
          offset: 120,
          showClose: true
        })
        return
      }
      if(!Number.isInteger(this.score)){
        this.$message({
          type: 'error',
          message: '分数格式不正确！',
          center: true,
          offset: 120,
          showClose: true
        })
        return
      }
      let loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen:false
      })
      let temp = await API.Public.LineDiagnosisScore({
        DiagnosisId: this.$route.query.id,
        DiagnosisScore: this.score
      })
      this.result = temp.data
      this.showResult = true
      loading.close()
      if(!temp.data.Score){
        this.$message({
          type: 'info',
          message: '暂无数据',
          offset: 120,
          showClose: true
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.diagnosis_box{
  max-width: 1420px;
  margin: auto;
  min-height: 600px;
  .htop{
    width: 100%;
    height: 70px;
    background-color: rgba(var(--themecolor),0.2);
    text-align: center;
    .head{
      color: #333;
      font-size: 18px;
      line-height: 70px;
      span{
        margin: 0px 10px;
      }
    }
  }
  .sform{
    border: 1px solid #e0e0e0;
    border-top: none;
    min-height: 150px;
    text-align: center;
    .box{
      width: 486px;
      margin: 50px auto;
      .title{
        width: 76px;
        color: #666;
        font-size: 14px;
        line-height: 50px;
        float: left;
        text-align: right;
      }
    }
    .show{
      border-top: 1px dashed #d4d4d4;
      .title{
        line-height: 40px;
        padding-top: 25px;
      }
      &-item{
        margin: 10px 20px 0;
        display: inline-block;
        .show-content{
          color: rgba(var(--themecolor),1);
          font-size: 30px;
        }
      }
    }
  }
}

</style>
<style lang="less">
.diagnosis_box .el-input{
  float: left;
  width: 300px;
}
.diagnosis_box .el-input__inner{
  height: 50px;
  font-size: 20px;
}
.diagnosis_box .el-input__inner:focus{
  border-color: rgba(var(--themecolor),0.75);
}



.diagnosis_box .el-button--primary{
  width: 100px;
  height: 50px;
  margin-left: 10px;
  float: left;
  font-size: 18px;
  letter-spacing: 2px;
}
.diagnosis_box .el-button--primary:hover{
  background: rgba(var(--themecolor),1);
  border-color: rgba(var(--themecolor),1);
}
.diagnosis_box .el-button--primary,.diagnosis_box .el-button--primary:focus{
  background: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}

</style>